import { BreadType } from "../../types";
import { country } from "./country";
import { honeySpelt } from "./honeySpelt";
import { spicedFruit } from "./spicedFruit";
import { miche } from "./miche";
import { khorasanRye } from "./khorasanRye";
import { emmer } from "./emmer";

const breads: BreadType[] = [
  country,
  honeySpelt,
  spicedFruit,
  miche,
  khorasanRye,
  emmer,
];

export default breads;
