export type ProofingVesselType = {
  [key: string]: number;
};

export const proofingVessels = {
  batard800: 17,
  batard1200: 2,
  batard1000: 5,
  boule800: 17,
  boule2500: 1,
  tin800: 24,
};

export type ProofingVesselOptionsType = keyof typeof proofingVessels;

export type IngredientTypeType =
  | "flour"
  | "liquid"
  | "salt"
  | "other"
  | "levain";

export interface FlourType {
  name: string;
  costPerGram: number;
}

export interface IngredientType {
  type: IngredientTypeType;
  name: string;
  description?: string;
  costPerGram: number;
}

interface StepIngredientType {
  ingredient: IngredientType | FlourType;
  bakersPercentage: number;
}

export interface BreadVariantType {
  name: string;
  weight: number;
  price: number;
  proofingVessel: ProofingVesselOptionsType;
}

export interface StepType {
  name: string;
  ingredients: StepIngredientType[];
  description?: string;
  inBowl: boolean;
  duration: number;
}

export interface BreadType {
  id: string;
  name: string;
  desc: string;
  baseFlours: {
    flour: FlourType;
    percentage: number;
  }[];
  saltPercentage: number;
  hydration: number;
  variants: BreadVariantType[];
  preparationSteps: StepType[];
  steps: StepType[];
}

export interface OrderLineType {
  bakeDate: string;
  mobileNumber: string;
  name: string;
  breadId: string;
  qty: number;
  proofingVessel: ProofingVesselOptionsType;
  variant: number;
}
