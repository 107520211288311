import { BreadType } from "../../types";
import { bakersFlour, wholegrainBakersFlour } from "../flours";

export const country: BreadType = {
  id: "country",
  name: "Country",
  desc: "Classic sourdough: 10% wholegrain flour.",
  hydration: 75,
  saltPercentage: 2,

  variants: [
    {
      name: "Regular",
      weight: 800,
      price: 6.5,
      proofingVessel: "batard800",
    },
    {
      name: "Large",
      weight: 1000,
      price: 8,
      proofingVessel: "batard1000",
    },
    {
      name: "Extra large",
      weight: 1200,
      price: 9.2,
      proofingVessel: "batard1200",
    },
  ],
  baseFlours: [
    {
      flour: bakersFlour,
      percentage: 90,
    },
    {
      flour: wholegrainBakersFlour,
      percentage: 10,
    },
  ],
  preparationSteps: [],
  steps: [
    {
      name: "First mix",
      inBowl: true,
      duration: 40,
      ingredients: [
        {
          ingredient: bakersFlour,
          bakersPercentage: 90,
        },
        {
          ingredient: wholegrainBakersFlour,
          bakersPercentage: 10,
        },
        {
          ingredient: {
            name: "Levain",
            type: "levain",
            costPerGram: bakersFlour.costPerGram / 2,
          },
          bakersPercentage: 20,
        },
        {
          ingredient: {
            name: "Water",
            type: "liquid",
            costPerGram: 0.00001,
          },
          bakersPercentage: 70,
        },
      ],
    },
    {
      name: "Second mix",
      inBowl: true,
      duration: 30,
      ingredients: [
        {
          ingredient: {
            name: "Salt",
            type: "salt",
            costPerGram: 0.037,
          },
          bakersPercentage: 2,
        },
        {
          ingredient: {
            name: "Water",
            type: "liquid",
            costPerGram: 0.00001,
          },
          bakersPercentage: 70,
        },
      ],
    },
    {
      name: "Fold 1",
      inBowl: false,
      duration: 30,
      ingredients: [],
    },
    {
      name: "Fold 2",
      inBowl: false,
      duration: 30,
      ingredients: [],
    },
    {
      name: "Fold 3",
      inBowl: false,
      duration: 30,
      ingredients: [],
    },
    {
      name: "Fold 4",
      inBowl: false,
      duration: 30,
      ingredients: [],
    },
    {
      name: "Fold 5",
      inBowl: false,
      duration: 60,
      ingredients: [],
    },
    {
      name: "Pre-shape",
      inBowl: false,
      duration: 20,
      ingredients: [],
    },
    {
      name: "Final shape",
      inBowl: false,
      duration: 120,
      ingredients: [],
    },
    {
      name: "Refrigerate loaves",
      inBowl: false,
      duration: 0,
      ingredients: [],
    },
  ],
};
