import { IngredientType } from "../types";

export const bakersFlour: IngredientType = {
  name: "Bakers flour",
  type: "flour",
  costPerGram: 0.0036728,
};

export const wholegrainBakersFlour: IngredientType = {
  name: "Wholegrain bakers flour",
  type: "flour",
  costPerGram: 0.00379,
};
