import axios from "axios";
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import ReactLoading from "react-loading";

import breads from "./data/breads";
import {
  OrderLineType,
  ProofingVesselOptionsType,
  proofingVessels,
} from "./types";

type OrderStatusType = "open" | "submitting" | "complete";

const bakeDate = "202107003";

const New: React.FC<{}> = () => {
  const intervalId = React.useRef<number | null>(null);
  const [status, setStatus] = React.useState<OrderStatusType>("open");
  const [orderDetails, setOrderDetails] = React.useState<{
    name: string;
    mobilePhone: string;
  }>({ name: "", mobilePhone: "" });
  const [orderItems, setOrderItems] = React.useState<{ [key: string]: number }>(
    {}
  );
  const [proofingVesselsCapacity, setProofingVesselsCapacity] = React.useState<
    {
      [key in ProofingVesselOptionsType]: number;
    }
  >(proofingVessels);

  // on mount start interval to check bread capacity
  React.useEffect(() => {
    const getRemainingCapacity = async () => {
      const result = await axios.get(
        `https://65njnvwzd3.execute-api.ap-southeast-2.amazonaws.com/Prod/getRemainingCapacity/${bakeDate}`
      );

      const workingProofingVessels = _.clone(proofingVessels);
      _.mergeWith(workingProofingVessels, result.data, (a, b) => a - b);
      setProofingVesselsCapacity(workingProofingVessels);
    };

    intervalId.current = window.setInterval(getRemainingCapacity, 3000);

    return () => {
      if (typeof intervalId.current === "number") {
        window.clearInterval(intervalId.current);
      }
    };
  }, []);

  // Detect changes to bread cap and alert user when if their cart needs adjusting
  React.useEffect(() => {
    // build alert string and update user cart
    const string = Object.keys(orderItems).reduce((prev, cur) => {
      const qty = orderItems[cur];

      const [id, weight] = cur.split("-");
      const foundBread = breads.find((bread) => bread.id === id);
      const foundVariant = foundBread?.variants.find(
        (variant) => variant.weight === parseInt(weight, 10)
      );

      if (!foundVariant || !foundBread) {
        return prev;
      }

      const capacity = proofingVesselsCapacity[foundVariant.proofingVessel];
      if (qty > 0 && capacity === 0) {
        prev += `Sorry ${foundBread.name} (${foundVariant.name}) has just sold out\n\n`;
      } else if (qty > capacity) {
        prev += `Sorry only ${capacity} ${foundBread.name} (${foundVariant.name}) is aviable. Your order has been adjusted.\n\n`;
      }

      if (qty > capacity) {
        setOrderItems((state) => ({
          ...state,
          [cur]: capacity,
        }));
      }

      return prev;
    }, "");

    if (string.length > 0 && status === "open") {
      alert(string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofingVesselsCapacity]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let { name, value } = e.target;

    setOrderItems((state) => ({
      ...state,
      [name]: parseInt(value, 10),
    }));
  };

  const handleOrderDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setOrderDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const getTotal = () => {
    return Object.keys(orderItems).reduce((workingTotal, breadKey) => {
      const qty = orderItems[breadKey];

      if (qty === 0) {
        return workingTotal;
      }

      const [id, weight] = breadKey.split("-");
      const foundBread = breads.find((bread) => bread.id === id);
      const foundVariant = foundBread?.variants.find(
        (variant) => variant.weight === parseInt(weight, 10)
      );

      if (!foundVariant || !foundBread) {
        return workingTotal;
      }

      return workingTotal + foundVariant.price * qty;
    }, 0);
  };

  const validation = () => {
    if (orderDetails.name.length === 0) {
      return "Please enter your name";
    }

    if (orderDetails.mobilePhone.length === 0) {
      return "Please enter you mobile number";
    }
    const pattern = /^(04[0-9]{8})$/;
    if (!pattern.test(orderDetails.mobilePhone)) {
      return "Please entry a valid mobile number (04XXXXXXXX)";
    }

    if (getTotal() === 0) {
      return "Your order is empty";
    }
    return "";
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setStatus("submitting");
    // build order
    const items: OrderLineType[] = Object.keys(orderItems).reduce(
      (dataObjects: OrderLineType[], breadKey) => {
        const qty = orderItems[breadKey];

        if (qty === 0) {
          return dataObjects;
        }

        const [id, weight] = breadKey.split("-");
        const foundBread = breads.find((bread) => bread.id === id);
        const foundVariant = foundBread?.variants.find(
          (variant) => variant.weight === parseInt(weight, 10)
        );

        if (!foundBread || !foundVariant) {
          return dataObjects;
        }

        return [
          ...dataObjects,
          {
            bakeDate,
            mobileNumber: orderDetails.mobilePhone,
            name: orderDetails.name,
            breadId: foundBread.id,
            qty,
            proofingVessel: foundVariant.proofingVessel,
            variant: foundVariant.weight,
          },
        ];
      },
      []
    );

    const result = await axios.post(
      `https://65njnvwzd3.execute-api.ap-southeast-2.amazonaws.com/Prod/addOrder`,
      { items }
    );

    console.log(result);

    setStatus("complete");
  };

  if (status === "submitting") {
    return (
      <Loading>
        <ReactLoading type="spin" height={120} width={120} color="gray" />
      </Loading>
    );
  }

  if (status === "complete") {
    return (
      <Loading>
        <h2>Your order has been submitted!</h2>
        <p>
          <strong>TOTAL:</strong> ${getTotal()}
          <br />
          <strong>NAME:</strong> Jolane Synott
          <br />
          <strong>BSB:</strong> 923100
          <br />
          <strong>ACC:</strong> 36386301
          <br />
          <i>Please use your name as the reference.</i>
        </p>
      </Loading>
    );
  }

  return (
    <>
      <Wrap>
        <div>
          <h1>Jolane's BIG birthday bake (3rd July)</h1>
          <h2>Orders close 10PM 1st July </h2>
          <p>
            <strong>Bank details:</strong>
            <br />
            Name: Jolane Synott
            <br />
            BSB: 923100
            <br />
            ACC: 36386301
            <br />
            Please use your name as the reference.
          </p>
          <Section>
            <h3>Your details</h3>
            <p>
              <label htmlFor="name">Full Name</label>
              <br />
              <input
                type="text"
                onChange={handleOrderDetailsChange}
                value={orderDetails.name}
                name="name"
              />
            </p>
            <p>
              <label htmlFor="name">Mobile phone</label>
              <br />
              <input
                type="text"
                onChange={handleOrderDetailsChange}
                value={orderDetails.mobilePhone}
                name="mobilePhone"
              />
            </p>
          </Section>
          <Section>
            {breads.map((bread) => (
              <div key={bread.name}>
                <h3>{bread.name}</h3>
                <p>{bread.desc}</p>
                <Table>
                  <tbody>
                    {bread.variants.map((variant) => {
                      const name: string = `${bread.id}-${variant.weight}`;

                      let leftString = "";
                      if (
                        proofingVesselsCapacity[variant.proofingVessel] === 0
                      ) {
                        leftString = "Sold out";
                      } else if (
                        proofingVesselsCapacity[variant.proofingVessel] < 5
                      ) {
                        leftString = `Only ${
                          proofingVesselsCapacity[variant.proofingVessel]
                        } left`;
                      }

                      const value = (orderItems && orderItems[name]) || "";
                      const qty = (orderItems && orderItems[name]) || 0;

                      return (
                        <tr key={variant.name}>
                          <td>{variant.name}</td>
                          <td>{leftString}</td>
                          <td>${variant.price}</td>
                          <td>
                            {proofingVesselsCapacity[variant.proofingVessel] >
                            0 ? (
                              <input
                                type="number"
                                pattern="[0-9]*"
                                min="0"
                                max={
                                  proofingVesselsCapacity[
                                    variant.proofingVessel
                                  ]
                                }
                                name={name}
                                value={value}
                                onChange={handleChange}
                              />
                            ) : (
                              <span>Sold out</span>
                            )}
                          </td>
                          <td>${qty * variant.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ))}
            <br />
            <Table>
              <tfoot>
                <tr>
                  <td colSpan={4}></td>
                  <td>
                    <strong>Total: ${getTotal()}</strong>
                  </td>
                </tr>
              </tfoot>
            </Table>

            <br />

            <Table>
              <tfoot>
                <tr>
                  <td colSpan={5} align="right">
                    {" "}
                    <p style={{ color: "red" }}>{validation()}</p>
                  </td>
                </tr>
              </tfoot>
            </Table>
            <Table>
              <tfoot>
                <tr>
                  <td colSpan={4}></td>
                  <td>
                    <button
                      onClick={handleSubmit}
                      disabled={validation() !== ""}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Section>
        </div>
      </Wrap>
      <Closed>
        <h1>Orders Closed</h1>
      </Closed>
    </>
  );
};

export default New;

const Wrap = styled.section`
  font-family: "Raleway", sans-serif;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;

  > div {
    width: 100%;
    max-width: 800px;
    padding: 1em;
  }

  label {
    font-weight: bold;
  }
  input {
    border: 1px solid gray;
    border-radius: 3px;
    font-size: 1.1em;
    padding: 0.12em;
  }
`;

const Section = styled.div`
  padding: 1em;
  border: 1px solid gray;
  & + & {
    border-top-width: 0;
  }

  &:last-child {
    margin-bottom: 5em;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  td {
    border-bottom: 1px solid gray;
    padding: 0.25em 0;
  }
  td:first-child {
    width: 50%;
  }

  td:nth-child(2) {
    width: 15%;
  }
  td:nth-child(3) {
    width: 15%;
    text-align: right;
  }
  td:nth-child(4) {
    width: 15%;
    text-align: right;
  }

  td:last-child {
    text-align: right;
  }

  tr:last-child td {
    border-bottom: none;
  }

  input {
    font-size: 1.1em;
  }

  button {
    font-size: 1.1em;
  }
`;

const Loading = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Closed = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 101;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;
